import { all, call } from "redux-saga/effects";
import { watchRequestPostPaymentDataImport } from "./importPaymentData/sagas";
import { watchRequestQueryAssetsData } from "./queryAssets/sagas";
import { watchRequestPaymentData } from "./paymentData/sagas";
import { watchRequestPaymentDataSummary } from "./paymentDataSummary/sagas";
import { watchRequestQuerySelfBillData } from "./querySelfBill/sagas";
import {
  watchRequestAssetTrueUp,
  watchRequestDetailAssetsData,
  watchRequestUpdateAssetsData,
} from "./detailAssets/sagas";
import {
  watchRequestQueryAlesData,
  watchRequestRevshareReceivedDrilldown,
} from "./queryAles/sagas";
import {
  watchInitializeQueryProducts,
  watchRequestQueryProductsData,
} from "./queryProducts/sagas";
import {
  watchDeleteProductRate,
  watchRequestDetailProductData,
  watchRequestUpdateProduct,
} from "./detailProducts/sagas";
import { watchRequestFinancialBreakdown } from "./financialBreakdown/sagas";
import {
  watchInitialiseAssetProductChangeForm,
  watchRequestSubmitAssetProductChange,
  watchSetBaseValuesAssetProductChangeForm,
} from "./formAssetProductChange/sagas";
import {
  watchInitializeEditProductRateForm,
  // watchSubmitEditProductRateEndDate,
  watchSubmitEditProductRateForm,
} from "./editProductRateForm/sagas";
import { watchCreateSecondaryProductRateForm } from "./createSecondaryProductForm/sagas";
import { watchSubmitCreateProductRateForm } from "./createProductRateForm/sagas";
import {
  watchCreateAssetFormInitialize,
  watchCreateAssetFormSubmit,
  watchSetPrimaryProductCreateAssetForm,
  watchSetValuesCreateAssetForm,
} from "./createAssetForm/sagas";
import { watchRequestPermissionsData } from "./permissions/sagas";
import { watchRequestAssetReportData } from "./assetFinancialsReport/sagas";
import { watchRequestCommentsData } from "./comments/sagas";
import { watchRequestUploadTemplateAmendments } from "./uploadTemplateAmendments/sagas";
import { watchDeferredCostReportData } from "./deferredCostReport/sagas";
import { watchRequestAssetInterfaceReportData } from "./interfaceAssetReport/sagas";
import {
  watchCreatePrimaryProduct,
  watchInitializeCreatePrimaryProduct,
  watchSetValuesCreatePrimaryProduct,
} from "./createPrimaryProductForm/sagas";
import { watchRequestQueryPartnersData } from "./queryPartners/sagas";
import { watchRequestDetailPartnerData } from "./detailPartners/sagas";
import { watchRequestPortAndResignReportData } from "./portAndResignReport/sagas";
import { watchRequestUnraisedRevshareReportData } from "./unraisedRevshareReport/sagas";
import { watchRequestOutstandingCommissionsReportData } from "./outstandingCommissionsReport/sagas";
import { watchRequestDisconRecUpValueReportReportData } from "./disconRecUpValueReport/sagas";
import { watchRecalculatePartnerRate } from "./formRecalculatePartnerRate/sagas";
import {
  watchRequestAssetIds,
  watchSubmitUpdateProofs,
} from "./formUpdateProofs/sagas";
import { watchSubmitEditPartnerRateForm } from "./editPartnerRateForm/sagas";
import {
  watchInitializePartnerRateForm,
  watchSetProductTypeId,
  watchSubmitPartnerRateForm,
  watchSetServiceProviderRate,
} from "./createPartnerRateForm/sagas";
import { watchRequestProgressReportData } from "./progressReport/sagas";
import {
  watchInitializeOutOfContractForm,
  watchSubmitOutOfContractForm,
} from "./outOfContractModalForm/sagas";
import {
  watchInitBulkEditPartnerRateForm,
  watchSubmitBulkEditPartnerRateForm,
} from "./bulkEditPartnerRateForm/sagas";
import { watchPartnerRateAdjustmentReport } from "./partnerRateAdjustmentReport/sagas";
import { watchCreatePartnerServiceProvider } from "./createPartnerServiceProviderForm/sagas";
import { watchEditPartnerServiceProvider } from "./editPartnerServiceProviderForm/sagas";
import { watchPartnerAdminInformationReport } from "./partnerAdminInformationReport/sagas";
import { watchCreateOocRevenueShare } from "./createOocRevenueShareForm/sagas";
import { watchSubmitEditOocPaymentTermForm } from "./editOocRevenueShareForm/sagas";
import { watchDeleteSpendCap } from "./deleteSpendCapForm/sagas";
import { watchSubmitPartnerNoteForm } from "./createPartnerNoteForm/sagas";
import { watchRequestSelfBillToPartnersData } from "./querySelfBillToPartners/sagas";
import { watchConnectionCommissionsForAccrualsReport } from "./connectionCommissionForAccrualsReport/sagas";
import {
  watchSelfBillList,
  watchUpdatePostingDocumentDate,
  watchDeleteSelfBillInvoiceFromList,
  watchRefreshSelfBillInvoice,
  watchUpdateSelfBillOverrides,
} from "./selfBillList/sagas";
import {
  watchRequestQueryAssetChangesData,
  watchRequestSbAmountSumOfAssetChangesDue,
} from "./queryAssetChangesDue/sagas";
import { watchUpdatePartnerGeneralInfo } from "./updatePartnerGeneralInfo/sagas";
import { watchupdateSelfBillToPartner } from "./updateSelfBillToPartner/sagas";
import {
  watchDeleteInvoiceLineFromList,
  watchRequestQueryInvoiceLinesData,
} from "./queryInvoiceLines/sagas";
import { watchRequestQueryPostedDocumentLinesData } from "./queryPostedDocumentsLines/sagas";
import { watchPostedCreditList } from "./postedCreditList/sagas";
import { watchPostedInvoiceList } from "./postedInvoiceList/sagas";
import { watchCreditNoteLines } from "./creditNoteLines/sagas";
import { watchCreditNoteLinesHeader } from "./creditNoteLinesHeader/sagas";

export function* rootSaga() {
  yield all([
    call(watchCreateAssetFormInitialize),
    call(watchCreateAssetFormSubmit),
    call(watchCreatePartnerServiceProvider),
    call(watchCreatePrimaryProduct),
    call(watchCreateSecondaryProductRateForm),
    call(watchCreateOocRevenueShare),
    call(watchDeferredCostReportData),
    call(watchDeleteProductRate),
    call(watchDeleteSpendCap),
    call(watchEditPartnerServiceProvider),
    call(watchInitBulkEditPartnerRateForm),
    call(watchInitialiseAssetProductChangeForm),
    call(watchInitializeCreatePrimaryProduct),
    call(watchInitializeEditProductRateForm),
    call(watchInitializeOutOfContractForm),
    call(watchInitializePartnerRateForm),
    call(watchInitializeQueryProducts),
    call(watchPartnerAdminInformationReport),
    call(watchPartnerRateAdjustmentReport),
    call(watchRecalculatePartnerRate),
    call(watchRequestAssetIds),
    call(watchRequestAssetInterfaceReportData),
    call(watchRequestAssetReportData),
    call(watchRequestAssetTrueUp),
    call(watchRequestCommentsData),
    call(watchRequestDetailAssetsData),
    call(watchRequestDetailPartnerData),
    call(watchRequestDetailProductData),
    call(watchRequestDisconRecUpValueReportReportData),
    call(watchRequestFinancialBreakdown),
    call(watchRequestOutstandingCommissionsReportData),
    call(watchRequestPaymentData),
    call(watchRequestPaymentDataSummary),
    call(watchRequestPermissionsData),
    call(watchRequestPortAndResignReportData),
    call(watchRequestPostPaymentDataImport),
    call(watchRequestProgressReportData),
    call(watchRequestQueryAlesData),
    call(watchRequestQueryAssetsData),
    call(watchRequestQueryPartnersData),
    call(watchRequestQueryProductsData),
    call(watchRequestQuerySelfBillData),
    call(watchRequestRevshareReceivedDrilldown),
    call(watchRequestSubmitAssetProductChange),
    call(watchRequestUnraisedRevshareReportData),
    call(watchRequestUpdateAssetsData),
    call(watchRequestUpdateProduct),
    call(watchRequestUploadTemplateAmendments),
    call(watchSetBaseValuesAssetProductChangeForm),
    call(watchSetPrimaryProductCreateAssetForm),
    call(watchSetProductTypeId),
    call(watchSetServiceProviderRate),
    call(watchSetValuesCreateAssetForm),
    call(watchSetValuesCreatePrimaryProduct),
    call(watchSubmitBulkEditPartnerRateForm),
    call(watchSubmitCreateProductRateForm),
    call(watchSubmitEditPartnerRateForm),
    call(watchSubmitEditProductRateForm),
    call(watchSubmitOutOfContractForm),
    call(watchSubmitPartnerRateForm),
    call(watchSubmitUpdateProofs),
    call(watchSubmitEditOocPaymentTermForm),
    call(watchSubmitPartnerNoteForm),
    call(watchRequestSelfBillToPartnersData),
    call(watchConnectionCommissionsForAccrualsReport),
    call(watchSelfBillList),
    call(watchUpdatePostingDocumentDate),
    call(watchDeleteSelfBillInvoiceFromList),
    call(watchRefreshSelfBillInvoice),
    call(watchRequestQueryAssetChangesData),
    call(watchUpdatePartnerGeneralInfo),
    call(watchupdateSelfBillToPartner),
    call(watchRequestSbAmountSumOfAssetChangesDue),
    call(watchRequestQueryInvoiceLinesData),
    call(watchUpdateSelfBillOverrides),
    call(watchDeleteInvoiceLineFromList),
    call(watchRequestQueryPostedDocumentLinesData),
    call(watchPostedCreditList),
    call(watchPostedInvoiceList),
    call(watchCreditNoteLines),
    call(watchCreditNoteLinesHeader),
  ]);
}
