import { request } from "..";
import { DateString } from "../../shared/helpers/dates";
import { PaginatedResult, TableAPIRequestParams } from "../types";
import {
  combine,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { stringify } from "query-string";

export type QueryPostedDocumentApiItem = {
  selfBillReference: number | null;
  postingDate: DateString | null;
  reference: string;
  partnerCode: number;
  partnerName: string;
  sbTotalExcVat: number;
  sbTotalIncVat: number;
};

export type QueryPostedDocumentLinesApiItem = {
  assetId: number;
  identifier: string;
  customer: string;
  startDate: DateString | null;
  serviceProvider: string;
  productCode: string;
  description: string | null;
  glCode: number;
  partnerShareAmount: number | null;
  monthlyAdvanceRepayment: number | null;
  totalExVat: number | null;
};

export type QueryInvoiceLinesRequestParams = {
  tableParams: TableAPIRequestParams<QueryPostedDocumentLinesApiItem>;
  invoiceLineType: number;
  postedDocumentId: number;
};

export function getQueryPostedDocument(postedDocumentId: number) {
  return request<QueryPostedDocumentApiItem>(
    `posteddocument/posted-document-lines-header/${postedDocumentId}?invoiceLineType=1`
  );
}

export function getPostedDocumentLines(params: QueryInvoiceLinesRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const invoiceLineType = stringify({
    invoiceLineType: params.invoiceLineType,
  });
  return request<PaginatedResult<QueryPostedDocumentLinesApiItem>>(
    `posteddocument/posted-document-lines/${params.postedDocumentId}${combine([
      tableParamsQS,
      invoiceLineType,
    ])}`
  );
}
