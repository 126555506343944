import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../../../shared/components/DataTable";
import { Button, Checkbox, Tooltip } from "@mui/material";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { useQueryParams, withDefault } from "use-query-params";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import {
  CommaDelimitedArrayParam,
  dateRangeParser,
} from "../../../shared/helpers/queryParsing";
import { RootState } from "../../../store/rootReducer";
import {
  QuerySelfBillListFilterParams,
  SelfBillListApiItem,
} from "../../../api/invoice/self-bill-list";
import { TableAPIRequestParams } from "../../../api/types";
import {
  requestSelfBillList,
  resetSelfBillListItemsChecked,
  setSelfBillListAllItemsChecked,
  setSelfBillListItemChecked,
} from "../../../store/selfBillList/actions";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import {
  formatTrueMeansSuccessBooleanAsIcon,
  formatFalseMeansSuccessBooleanAsIcon,
  anyToBoolean,
} from "../../../shared/helpers/boolean";
import { openInNewTab } from "../../../shared/helpers/navigation";
import { PaymentTerms } from "../../../shared/enum/invoice";
import { ModalDateUpdate } from "./ModalDateUpdate";
import { formatISOForDisplay } from "./../../../shared/helpers/dates";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import { useHistory } from "react-router-dom";
import { ModalDeleteInvoice } from "./ModalDeleteInvoice";
import { ModalRefreshInvoice } from "./ModalRefreshInvoice";
import { makeStyles } from "tss-react/mui";
import PostSelfBillsButton from "./PostSelfBillsButton";

const useStyles = makeStyles()((theme) => ({
  checkmark: {
    width: "20px",
    padding: 0,
  },
  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
}));

export function SelfBillList() {
  const { items, fetching, error, count, checked } = useSelector(
    (state: RootState) => state.selfBillList
  );

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [filters, updateFilters] = useQueryParams({
    territoryCode: withDefault(CommaDelimitedArrayParam, []),
    postingDate: dateRangeParser,
    partnerCode: withDefault(CommaDelimitedArrayParam, []),
    postingIssue: withDefault(CommaDelimitedArrayParam, []),
  });

  const history = useHistory();

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<SelfBillListApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "invoiceId",
  });

  const filterMeta = [
    {
      name: "territoryCode",
      label: "Territory Code",
    },
    {
      name: "postingDate",
      label: "Posting Date",
      isDateRange: true,
    },
    {
      name: "partnerCode",
      label: "Partner Code",
    },
    {
      name: "postingIssue",
      label: "Posting Issue",
    },
  ];

  const cleanseRequestSelfBillListData = (
    filters: any
  ): QuerySelfBillListFilterParams => {
    const postingIssue = filters["postingIssue"];
    const { postingIssue: _, ...restFilters } = filters;

    return {
      ...restFilters,
      vatStatusQuery: postingIssue.includes("VAT Status") ? [true] : undefined,
      blocked: postingIssue.includes("Blocked") ? [true] : undefined,
      isPostingDateValid: postingIssue.includes("Posting Date")
        ? ["false"] // string is used here to not get filtered out in the cleanse process
        : undefined,
    };
  };

  useEffect(() => {
    dispatch(resetSelfBillListItemsChecked());
    dispatch(
      requestSelfBillList({
        tableControls: tableControls,
        filterParams: cleanseRequestSelfBillListData(filters),
      })
    );
  }, [dispatch, tableControls, filters]);

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentInvoiceId, setCurrentInvoiceId] = useState<number | null>(null);

  const handleDateClick = (date: string, invoiceId: number) => {
    setSelectedDate(new Date(date));
    setCurrentInvoiceId(invoiceId);
    setOpen(true);
  };

  const handleInvoiceIdClick = (invoiceId: number) => {
    history.push({
      pathname: `asset-changes`,
      state: {
        invoiceId: invoiceId,
      },
    });
  };

  const handlePartnerCodeClick = (partnerId: number) => {
    const url = `/partners/${partnerId}`;
    openInNewTab(url);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSaveDate = () => {
    setSelectedDate(null);
    setCurrentInvoiceId(null);
    dispatch(
      requestSelfBillList({
        tableControls: tableControls,
        filterParams: filters,
      })
    );
  };

  const actions = [
    {
      label: "Delete",
      renderComponent: (row: any) => (
        <ModalDeleteInvoice
          rowData={row}
          params={{
            tableControls: tableControls,
            filterParams: cleanseRequestSelfBillListData(filters),
          }}
        />
      ),
    },
    {
      label: "Refresh",
      renderComponent: (row: any) => (
        <ModalRefreshInvoice
          rowData={row}
          params={{
            tableControls: tableControls,
            filterParams: cleanseRequestSelfBillListData(filters),
          }}
        />
      ),
    },
  ];

  const CustomCheckbox = (_: string, rowData: SelfBillListApiItem) => {
    return (
      <Checkbox
        color="primary"
        size="small"
        className={classes.checkmark}
        data-cy={`filter-item-checkbox-${rowData.invoiceId}`}
        checked={checked.includes(rowData.invoiceId)}
        onChange={(e) =>
          dispatch(
            setSelfBillListItemChecked(rowData.invoiceId, e.target.checked)
          )
        }
      />
    );
  };

  const RowHeaderCheckbox = () => {
    return (
      <Checkbox
        color="primary"
        size="small"
        className={classes.checkmark}
        data-cy={"row-header-item-checkbox"}
        checked={items.length === checked.length}
        onChange={() => dispatch(setSelfBillListAllItemsChecked())}
      />
    );
  };

  let columns: {
    [key: string]: any;
    name: keyof SelfBillListApiItem;
    label: string | React.ReactNode;
    customBodyRender?: (
      input: string | number | boolean | null,
      rowData: any
    ) => React.ReactNode;
    align?: "left" | "right" | "center";
    disableSort?: boolean;
    allowControl?: boolean;
  }[] = [
    {
      name: "checked",
      label: RowHeaderCheckbox(),
      disableSort: true,
      allowControl: false,
      customBodyRender: (val, rowData) =>
        CustomCheckbox(val as string, rowData),
    },
    {
      name: "invoiceId",
      label: "SB No.",
      customBodyRender: (val) => {
        const id = `asset-changes-${val}`;
        return (
          <Button
            id={id}
            onClick={() => handleInvoiceIdClick(val as number)}
            className={classes.button}
          >
            {val}
          </Button>
        );
      },
    },
    { name: "reference", label: "Reference", disableSort: true },
    {
      name: "postingDate",
      label: "Posting Date",
      disableSort: true,
      customBodyRender: (val, rowData) => {
        const id = `posting-document-date-update-${rowData.invoiceId}`;
        return (
          <Button
            id={id}
            className={classes.button}
            onClick={() => handleDateClick(val as string, rowData.invoiceId)}
          >
            {formatISOForDisplay(val as string)}
          </Button>
        );
      },
    },
    {
      name: "paymentTerms",
      label: "Payment Terms",
      disableSort: true,
      customBodyRender: (val) => PaymentTerms[val as keyof typeof PaymentTerms],
    },
    {
      name: "partnerCode",
      label: "Partner Code",
      disableSort: true,
      customBodyRender: (value, rowData) => {
        return (
          <Button
            onClick={() => handlePartnerCodeClick(rowData.partnerId as number)}
            className={classes.button}
          >
            {value}
          </Button>
        );
      },
    },
    {
      name: "vatRegNo",
      label: "VAT Number",
      disableSort: true,
      customBodyRender: (value, rowData) => {
        if (value === "-") {
          value = "null";
        }
        const style = {
          color: rowData.isVatRegNoValid ? "inherit" : "red",
        };

        const content = <span style={style}>{value}</span>;

        return rowData.isVatRegNoValid ? (
          content
        ) : (
          <Tooltip
            title="VAT number is invalid.  Please update with a valid VAT value of either N/A or GB followed by 9 digits."
            placement="right-end"
          >
            {content}
          </Tooltip>
        );
      },
    },

    {
      name: "amountExcVat",
      label: "Amount (Ex VAT)",
      align: "right",
      disableSort: true,
      customBodyRender: (val, rowData) => {
        return rowData.isVatRegNoValid
          ? formatCurrencyGBP((val as number | null) || 0)
          : `-`;
      },
    },
    {
      name: "amountIncVat",
      label: "Amount (Inc VAT)",
      disableSort: true,
      align: "right",
      customBodyRender: (val, rowData) => {
        return rowData.isVatRegNoValid
          ? formatCurrencyGBP((val as number | null) || 0)
          : `-`;
      },
    },
    {
      name: "territoryCode",
      label: "Territory Code",
      align: "left",
      disableSort: true,
    },
    {
      name: "vatStatusQuery",
      label: "VAT Status",
      align: "center",
      customBodyRender: (val) => {
        return formatFalseMeansSuccessBooleanAsIcon(anyToBoolean(val));
      },
    },
    {
      name: "blocked",
      label: "Blocked",
      align: "center",
      customBodyRender: (val) => {
        return formatFalseMeansSuccessBooleanAsIcon(anyToBoolean(val));
      },
    },
    {
      name: "isPostingDateValid",
      label: "Valid Posting Date",
      align: "center",
      customBodyRender: (val) => {
        return formatTrueMeansSuccessBooleanAsIcon(anyToBoolean(val));
      },
    },
  ];

  return (
    <React.Fragment>
      <PostSelfBillsButton />
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        allowHidden={true}
        controls={() => (
          <React.Fragment>
            <MultiTextField
              type="text"
              name="Territory Code"
              id="territory-code-filter-control"
              selected={filters.territoryCode || []}
              onChange={(territoryCode) => updateFilters({ territoryCode })}
            />
            <DateRangeControl
              name="Posting Date"
              id="posting-date-filter-control"
              selected={filters.postingDate || [null, null]}
              onChange={(postingDate) => updateFilters({ postingDate })}
            />
            <MultiTextField
              type="text"
              name="Partner Code"
              id="posting-code-filter-control"
              selected={filters.partnerCode || []}
              onChange={(partnerCode) => updateFilters({ partnerCode })}
            />
            <MultiSelect
              name="Posting Issue"
              id="posting-issue-filter-control"
              options={["VAT Status", "Blocked", "Posting Date"]}
              selected={filters.postingIssue || []}
              onChange={(postingIssue) => updateFilters({ postingIssue })}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QuerySelfBillListFilterParams}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        title="Self Bill List"
        limitHeight
        stickyHeader
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          setTableControls(tableControls);
        }}
        tableControls={tableControls}
        allowColumnControl={true}
        hasActions={true}
        actions={actions}
      />
      <ModalDateUpdate
        open={open}
        onClose={handleModalClose}
        onSave={handleSaveDate}
        date={selectedDate}
        invoiceId={currentInvoiceId}
        tableControls={tableControls}
        filterParams={filters}
        cleanseRequestSelfBillListData={cleanseRequestSelfBillListData}
      />
    </React.Fragment>
  );
}
