import {
  SelfBillListApiItem,
  SelfBillListOverrideParams,
  SelfBillListParams,
} from "../../api/invoice/self-bill-list";
import { PaginatedResult, RequestError } from "../../api/types";

export const REQUEST_DATA = "selfBillList.REQUEST_DATA";
export const UPDATE_POSTING_DATE = "selfBillList.UPDATE_POSTING_DATE";
export const SET_DATA = "selfBillList.SET_DATA";
export const SET_ERROR = "selfBillList.SET_ERROR";
export const DELETE_INVOICE = "SelfBillList.DELETE_INVOICE";
export const REFRESH_INVOICE = "SelfBillList.REFRESH_INVOICE";
export const SET_ITEM_CHECKED = "selfBillList.SET_ITEM_CHECKED";
export const SET_ALL_ITEMS_CHECKED = "selfBillList.SET_ALL_ITEMS_CHECKED";
export const RESET_CHECKED_ITEMS = "selfBillList.RESET_CHECKED_ITEMS";
export const UPDATE_OVERRIDES = "selfBillList.UPDATE_OVERRIDES";

export type SelfBillListState = {
  items: SelfBillListItem[];
  checked: number[];
  fetching: boolean;
  count: number;
  error?: RequestError;
};

export type SelfBillListItem = SelfBillListApiItem & {
  checked: boolean;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: SelfBillListParams;
  };
}

export interface UpdatePostingDocumentDateAction {
  type: typeof UPDATE_POSTING_DATE;
  payload: {
    invoiceId: number;
    postingDate: Date;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<SelfBillListItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export interface DeleteInvoiceAction {
  type: typeof DELETE_INVOICE;
  payload: {
    invoiceId: number;
    params: SelfBillListParams;
  };
}

export interface RefreshInvoiceAction {
  type: typeof REFRESH_INVOICE;
  payload: {
    invoiceId: number;
    params: SelfBillListParams;
  };
}

export interface SetItemCheckedAction {
  type: typeof SET_ITEM_CHECKED;
  payload: {
    invoiceId: number;
    checked: boolean;
  };
}

export interface SetAllItemsCheckedAction {
  type: typeof SET_ALL_ITEMS_CHECKED;
}

export interface ResetCheckedItemsAction {
  type: typeof RESET_CHECKED_ITEMS;
}

export interface UpdateSelfBillListOverridesAction {
  type: typeof UPDATE_OVERRIDES;
  payload: {
    invoiceId: number;
    params: SelfBillListOverrideParams;
  };
}

export type SetBillListActionTypes =
  | RequestDataAction
  | UpdatePostingDocumentDateAction
  | SetDataAction
  | SetErrorAction
  | DeleteInvoiceAction
  | RefreshInvoiceAction
  | SetItemCheckedAction
  | SetAllItemsCheckedAction
  | ResetCheckedItemsAction
  | UpdateSelfBillListOverridesAction;
