import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  QueryPostedDocumentLinesApiItem,
  QueryInvoiceLinesRequestParams,
} from "../../api/posted-document";

export const REQUEST_DATA = "queryPostedDocumentLines.REQUEST_DATA";
export const SET_DATA = "queryPostedDocumentLines.SET_DATA";
export const SET_TABLE_CONTROLS = "queryPostedDocumentLines.SET_TABLE_CONTROLS";
export const SET_API_ERROR = "queryPostedDocumentLines.SET_API_ERROR";

export type QueryPosedDocumentLinesState = {
  items: QueryPostedDocumentLinesApiItem[];
  allItems: QueryPostedDocumentLinesApiItem[];
  count: number;
  fetching: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  tableControls: TableAPIRequestParams<QueryPostedDocumentLinesApiItem>;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: QueryInvoiceLinesRequestParams;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<QueryPostedDocumentLinesApiItem>;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: TableAPIRequestParams<QueryPostedDocumentLinesApiItem>;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type QueryPostedDocumentLinesActionsTypes =
  | RequestDataAction
  | SetDataAction
  | SetTableControlsAction
  | SetAPIErrorAction;
