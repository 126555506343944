import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FileDownload from "js-file-download";
import { RootState } from "../../../store/rootReducer";
import {
  CreditNoteLinesAPIItem,
  getCreditNoteLinesExcel,
} from "../../../api/posted-document/credit-note-lines/index";
import { TableAPIRequestParams } from "../../../api/types";
import { requestCreditNoteLines } from "../../../store/creditNoteLines/actions";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { Box, Button, Typography } from "@mui/material";
import { DataTable } from "../../../shared/components/DataTable";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { Enum } from "../../../shared/helpers/enum";
import { CreditNoteLinesHeader } from "./CreditNoteLinesHeader";

const useStyles = makeStyles()(() => ({
  exportButton: {
    marginRight: "10px",
  },
}));

interface LocationState {
  postedDocumentId: number;
}

export function CreditNoteLines() {
  const { items, fetching, error, count } = useSelector(
    (state: RootState) => state.creditNoteLines
  );

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation<LocationState>();

  const { postedDocumentId } = location.state || {};

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<CreditNoteLinesAPIItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "assetId",
  });

  const [isExporting, setIsExporting] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      requestCreditNoteLines({
        tableControls: tableControls,
        postedCreditNoteId: postedDocumentId,
      })
    );
  }, [dispatch, tableControls, postedDocumentId]);

  const handleExport = () => {
    setIsExporting(true);
    getCreditNoteLinesExcel({
      tableControls: tableControls,
      postedCreditNoteId: postedDocumentId,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
    setIsExporting(false);
  };

  const handleNavToPostedSbCreditList = () => {
    history.push(`/self-bill/credit-list`);
  };

  const columns: {
    [key: string]: any;
    name: keyof CreditNoteLinesAPIItem;
    label: string;
    customBodyRender?: (
      input: string | number | boolean | null,
      rowData: any
    ) => React.ReactNode;
    align?: "left" | "right" | "center";
    enum?: Enum<any>;
    disableSort?: boolean;
  }[] = [
    {
      name: "assetId",
      label: "Asset Id",
      align: "left",
    },
    {
      name: "identifier",
      label: "Identifier",
      align: "left",
    },
    {
      name: "customerName",
      label: "Customer",
      align: "left",
    },
    {
      name: "startDate",
      label: "Stat Date",
      align: "left",
      customBodyRender: (val) => formatISOForDisplay(val as string),
    },
    {
      name: "serviceProvider",
      label: "Service Provider",
      align: "left",
    },
    {
      name: "productCode",
      label: "Product Code",
      align: "left",
    },
    {
      name: "description",
      label: "Description",
      align: "left",
    },
    {
      name: "glCode",
      label: "GL Code",
      align: "left",
    },
    {
      name: "partnerShareAmount",
      label: "Partner Share Amount",
      align: "right",
      customBodyRender: (val, rowData) =>
        rowData.paymentType === "Upfront"
          ? formatCurrencyGBP(0)
          : formatCurrencyGBP((val as number | null) || 0),
      disableSort: true,
    },
    {
      name: "monthlyAdvanceRepayment",
      label: "Monthly Advance Repayment",
      align: "right",
      customBodyRender: (val, rowData) =>
        rowData.paymentType === "Upfront"
          ? formatCurrencyGBP(0)
          : formatCurrencyGBP((val as number | null) || 0),
      disableSort: true,
    },
    {
      name: "totalExVat",
      label: "Total (Exc VAT)",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
      disableSort: true,
    },
  ];

  return (
    <React.Fragment>
      <Typography variant="h3">Posted SB Credit Note Lines</Typography>
      <Box display="flex" justifyContent="flex-end" paddingBottom={1}>
        <Box marginRight={1}>
          <Button
            size="large"
            variant="contained"
            onClick={handleNavToPostedSbCreditList}
          >
            Back To Posted SB Credit List
          </Button>
        </Box>
        <LoaderButton
          size="large"
          variant="outlined"
          onClick={handleExport}
          loading={isExporting}
          className={classes.exportButton}
        >
          Export
        </LoaderButton>
      </Box>
      <CreditNoteLinesHeader />
      <DataTable
        error={error}
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          setTableControls(tableControls);
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
}
