export enum PaymentDataReason {
  "Not Set" = 0,
  "Not Found" = 1,
  "Active" = 2,
  "Out Of Contract" = 3,
  "Thirty Days" = 4,
  "Start Date Not Found" = 5,
  "OOCExempt" = 6,
}

export enum Status {
  "Unprocessed" = 0,
  "Matched" = 1,
  "Unmatched" = 2,
  "Processed" = 3,
}

export enum UpfrontPaymentType {
  "Not Set" = 0,
  "Commission" = 1,
  "Advance" = 2,
  "RevShare" = 3,
}

export enum SBPaymentType {
  "Not Set" = 0,
  "Upfront" = 1,
  "RevShare" = 2,
}

export enum RevShareSummaryProductType {
  "Mobile" = 1,
  "Mobile Broadband" = 2,
  "Digital" = 3,
  "Landline" = 4,
  "Broadband" = 5,
  "Data" = 6,
  "Application" = 7,
  "Blackberry" = 8,
}

export const contractTypes = [
  "New",
  "Pre-Pay",
  "Bundled",
  "Service",
  "Re-Sign",
  "Migration",
] as const;
export type ContractType = typeof contractTypes[number];

export const paymentTypes = ["REVSHARE", "UPFRONT", "UPLIFT"] as const;
export type PaymentType = typeof paymentTypes[number];

export const upfrontFileTypes = [
  "O2Advance",
  "O2VAS",
  "O2GCB",
  "EEUpfront",
  "VodafoneCashAdvance",
  "VodafoneQueries",
  "VodafoneSupplementary",
] as const;
export type UpfrontFileType = typeof upfrontFileTypes[number];
