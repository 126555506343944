import {
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  CreditNoteLinesHeaderState,
  CreditNoteLinesHeaderActionTypes,
} from "./types";

const initialState: CreditNoteLinesHeaderState = {
  fetching: false,
  data: {
    selfBillReference: "",
    partnerCode: "",
    partnerName: "",
    postingDate: "",
    reference: "",
    sbTotalExcVat: 0,
    sbTotalIncVat: 0,
  },
};

export function creditNoteLinesHeaderReducer(
  state = initialState,
  action: CreditNoteLinesHeaderActionTypes
): CreditNoteLinesHeaderState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload.err,
        fetching: false,
      };

    case SET_DATA:
      return {
        ...state,
        fetching: false,
        data: action.payload.data,
      };

    default:
      return state;
  }
}
