import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
// import { ViewPostedDocument } from "./ViewPostedDocument";

export const PostedDocuments = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        {/*<ViewPostedDocument invoiceLineType={2} />*/}
      </Route>
    </Switch>
  );
};
