import { makeStyles } from "tss-react/mui";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    overflow: "hidden",
  },
  container: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  controls: {
    marginTop: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      marginTop: theme.spacing(1),
      marginRight: "16px !important",
    },
    width: "100%",
  },
}));

type Props = {
  countLabel?: number;
  chips: () => React.ReactNode;
  onResetAll: () => any;
  search?: () => React.ReactNode;
  controls: () => React.ReactNode;
  allowHidden?: boolean;
};

export const FiltersWrapper = ({
  countLabel,
  onResetAll,
  search,
  controls,
  chips,
  allowHidden = false,
}: Props) => {
  const { classes } = useStyles();

  const [hidden, setHidden] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Grid container className={classes.container}>
          <Grid item xs={9}>
            <Box display="flex" alignItems="baseline">
              <Typography variant="h3">
                {`${search ? "Search & " : ""}Filters`}
              </Typography>
              {countLabel && (
                <span style={{ marginLeft: 18 }}>({countLabel} total)</span>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                color="primary"
                onClick={onResetAll}
                size="small"
              >
                Clear all filters
              </Button>
              {allowHidden && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setHidden((current) => !current)}
                  size="small"
                  endIcon={hidden ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                >
                  {hidden ? "Open" : "Hide"}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {!hidden && (
          <Grid container className={classes.container}>
            {search && (
              <Box mb={1} minWidth={700} width="100%" display="flex">
                {search()}
              </Box>
            )}
            <div className={classes.controls}>{controls()}</div>
          </Grid>
        )}
        {chips()}
      </Card>
    </React.Fragment>
  );
};
