import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../store/rootReducer";
import { requestCreditNoteLinesHeader } from "../../../store/creditNoteLinesHeader/actions";
import { Grid, Card } from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";

interface LocationState {
  postedDocumentId: number;
}

export function CreditNoteLinesHeader() {
  const { data } = useSelector(
    (state: RootState) => state.creditNoteLinesHeader
  );

  const useStyles = makeStyles()((theme) => ({
    card: {
      marginBottom: theme.spacing(2),
      overflow: "hidden",
    },
    container: {
      padding: theme.spacing(1.5, 2),
    },
    itemContainer: {
      marginBottom: theme.spacing(1),
    },
    label: {
      width: "150px",
      fontWeight: "bold",
    },
    value: {
      flex: 1,
      wordBreak: "break-word",
    },
  }));

  const { classes } = useStyles();

  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const { postedDocumentId } = location.state || {};

  useEffect(() => {
    dispatch(requestCreditNoteLinesHeader(postedDocumentId));
  }, [dispatch, postedDocumentId]);

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Grid container className={classes.container}>
          <Grid item xs={4} xl={3}>
            <Grid
              container
              alignItems="center"
              className={classes.itemContainer}
            >
              <Grid item className={classes.label}>
                SB Reference:
              </Grid>
              <Grid item className={classes.value}>
                {data?.selfBillReference || ""}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={classes.itemContainer}
            >
              <Grid item className={classes.label}>
                Partner Code:
              </Grid>
              <Grid item className={classes.value}>
                {data?.partnerCode || ""}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={classes.itemContainer}
            >
              <Grid item className={classes.label}>
                Partner Name:
              </Grid>
              <Grid item className={classes.value}>
                {data?.partnerName || ""}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} xl={3}>
            <Grid
              container
              alignItems="center"
              className={classes.itemContainer}
            >
              <Grid item className={classes.label}>
                Posting Date:
              </Grid>
              <Grid item className={classes.value}>
                {formatISOForDisplay(data?.postingDate || "")}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={classes.itemContainer}
            >
              <Grid item className={classes.label}>
                Reference:
              </Grid>
              <Grid item className={classes.value}>
                {data?.reference || ""}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} xl={3}>
            <Grid
              container
              alignItems="center"
              className={classes.itemContainer}
            >
              <Grid item className={classes.label}>
                SB Total (Exc VAT):
              </Grid>
              <Grid item className={classes.value}>
                {formatCurrencyGBP(data?.sbTotalExcVat || 0)}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={classes.itemContainer}
            >
              <Grid item className={classes.label}>
                SB Total (Inc VAT):
              </Grid>
              <Grid item className={classes.value}>
                {formatCurrencyGBP(data?.sbTotalIncVat || 0)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
