import React, { useState } from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { includes, toArray } from "lodash";
import pickBy from "lodash/pickBy";
import { makeStyles } from "tss-react/mui";
import { DraggableModal } from "../../../shared/components/DraggableModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { SelfBillListItem } from "../../../store/selfBillList/types";

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
}));

export default function PostSelfBillsButton() {
  const { classes } = useStyles();

  const { items, checked } = useSelector(
    (state: RootState) => state.selfBillList
  );
  const [openPostSelfBillsModal, setOpenPostSelfBillsModal] = useState<boolean>(
    false
  );

  const checkedItems = toArray(
    pickBy(items, (i: SelfBillListItem) => includes(checked, i.invoiceId))
  );

  const hasInvalidVat = checkedItems.some((item) => !item.isVatRegNoValid);

  return (
    <Box display="flex" justifyContent="flex-end" paddingBottom={1}>
      <Button
        variant="contained"
        aria-label="Button for post self bills"
        disabled={checked.length === 0 || hasInvalidVat}
        size="large"
        onClick={() => setOpenPostSelfBillsModal(true)}
      >
        Post Self Bills
      </Button>

      <DraggableModal
        aria-describedby="post-self-bills-modal"
        aria-labelledby="post-self-bills-modal-title"
        open={openPostSelfBillsModal}
        onClose={() => setOpenPostSelfBillsModal(false)}
      >
        <Card className={classes.card} id="post-self-bills-modal-title">
          <Box mt={2} mb={4} justifyContent="center">
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              id="post-self-bills-modal"
            >
              {"Post Self Bills"}
            </Typography>
          </Box>
        </Card>
      </DraggableModal>
    </Box>
  );
}
